import React from "react";
import { Link } from "react-router-dom";
import "./allLone.css";

function RealEstateLoans() {
  return (
    <div>
      <div className="ra-con">
        {/* <Background bgImage={bgImage} /> */}

        <div className="con-img">
          <div className="con-text">
            <div className="container">
              <div className="con-hed">
                <h1>
                  Real Estate
                  <br />
                  Loans
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#0A0A0A" }}>
        {/* Inner Banner Section */}
        <section className="about-banner pt-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="text-white">Real Estate Loans</h1>
              </div>
            </div>
          </div>
        </section>

        {/* Real Estate Section */}
        <div className="realestate-section area-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12 pb-5">
                <div className="commercial-section">
                  <div className="commercial-icon">
                    <img
                      src="/img/loan/real-logo1.png"
                      alt=""
                      title=""
                    />
                  </div>
                  <h2>commercial real estate</h2>
                  <p>
                    Building out your business location is a good way to
                    increase your assets. Each renovation, expansion, and
                    upgrade increases the value of your property and gives the
                    space needed to streamline the business and gain customers.
                    <br />
                    <br />A commercial mortgage can help in achieving this, and
                    forming a strong foundation for your small business.
                  </p>
                  <a
                    className="btn btn-warning"
                    href="https://app.lendingwise.com/HMLOWebForm.php?bRc=91d31e8a24296cbe&#038;aRc=a9056fd458f47dc8&#038;fOpt=c1cca7825dbc8710&#038;op=aa4465703ef4b17e"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply Now
                  </a>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12 pb-5">
                <div className="commercial-section">
                  <div className="commercial-icon">
                    <img
                      src="/img/loan/real-logo2.png"
                      alt=""
                      title=""
                    />
                  </div>
                  <h2>1-4 unit residential investment properties</h2>
                  <p>
                    One to four unit rentals is a great way to get started in
                    real estate investing. Ensure you get started right with the
                    right terms and guidance.
                    <br />
                    <br />
                    Find the right loan based on your long term goals.
                  </p>
                  <a
                    className="btn btn-warning"
                    href="https://app.lendingwise.com/HMLOWebForm.php?bRc=91d31e8a24296cbe&#038;aRc=a9056fd458f47dc8&#038;fOpt=c1cca7825dbc8710&#038;op=aa4465703ef4b17e"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply Now
                  </a>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12 pb-5">
                <div className="commercial-section">
                  <div className="commercial-icon">
                    <img
                      src="/img/loan/real-logo3.png"
                      alt=""
                      title=""
                    />
                  </div>
                  <h2>5+ unit residential investment properties</h2>
                  <p>
                    Use for investments in your business, like large purchases
                    or expansion.
                    <br />
                    <br />
                    Get the funds needed upfront with great rates, and the
                    option to apply for more when needed.
                  </p>
                  <a
                    className="btn btn-warning"
                    href="https://app.lendingwise.com/HMLOWebForm.php?bRc=91d31e8a24296cbe&#038;aRc=a9056fd458f47dc8&#038;fOpt=c1cca7825dbc8710&#038;op=aa4465703ef4b17e"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply Now
                  </a>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12 pb-5">
                <div className="commercial-section">
                  <div className="commercial-icon">
                    <img
                      src="/img/loan/real-logo4.png"
                      alt=""
                      title=""
                    />
                  </div>
                  <h2>short term rentals &amp; airbnb</h2>
                  <p>
                    Short term rentals, rentals less than 7 days. A thriving
                    market. Work with lenders that understand this market and
                    specialize in financing these great investment
                    opportunities.
                  </p>
                  <a
                    className="btn btn-warning"
                    href="https://app.lendingwise.com/HMLOWebForm.php?bRc=91d31e8a24296cbe&#038;aRc=a9056fd458f47dc8&#038;fOpt=c1cca7825dbc8710&#038;op=aa4465703ef4b17e"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Qualify Merchant Section */}
        <div className="qualifymerchant-section area-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/real-img1.jpg"
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-8 col-12">
                <h3>
                  <span style={{ color: "#FF0202" }}>
                    Commercial And Investment Real Estate Term Loans
                  </span>
                </h3>
                <p style={{ color: "#FFF" }}>
                  By use the latest technology like A.I. , modern web design
                  &amp; database architecture, we have created a platform that
                  make sit easy to submit loans accurately, collect required
                  docs, find the best lenders &amp; close loans faster &amp;
                  cheaper than ever before.
                </p>
                <Link
                  className="btn btn-warning"
                  to="/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Questions?
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Merchant Advance Section */}
        <div className="merchantadvance-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3>
                  <span style={{ color: "#FF0202" }}>
                    Loans For Rental Real Estate
                  </span>
                </h3>
                <p style={{ color: "#FFF" }}>
                  If you're a business owner in the real estate industry that
                  owns or manages rental properties, Broco Financial Services offers loans that
                  can help you finance your next project. These loans are
                  typically long-term loans that can be used to finance the
                  purchase or renovation of a rental property. These loans
                  usually have interest-only payments during the renovation
                  phase, and they can be used to finance both residential and
                  commercial properties. From permanent loans to construction
                  loans, mezzanine loans, and more, our team can connect you
                  with the financing you need for your next project. Contact us
                  today for more information on our rental property loans!
                </p>
                <Link
                  className="btn btn-warning"
                  to="/contact" 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Questions?
                </Link>
              </div>

              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg">
                  <img
                    src="/img/loan/real-img2.jpg"
                    alt=""
                    title=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Qualify Merchant Section */}
        <div className="qualifymerchant-section area-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/real-img3.jpg"
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-8 col-12">
                <h3>
                  <span style={{ color: "#FF0202" }}>Commercial Mortgage</span>
                </h3>
                <p style={{ color: "#FFF" }}>
                  Broco Financial Services also offers commercial mortgage loans for businesses
                  that want to purchase or refinance commercial property. Our
                  team can provide you with owner-occupied loans, investment
                  property loans, small business administration loans, and other
                  commercial mortgage financing solutions at favorable rates. We
                  are passionate about helping small businesses grow and thrive,
                  so contact us today to learn more about our commercial
                  mortgage loan solutions!
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Merchant Advance Section */}
        <div className="merchantadvance-section area-padding pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3>
                  <span style={{ color: "#FF0202" }}>How Broco Financial Services Helps</span>
                </h3>
                <p style={{ color: "#FFF" }}>
                  Broco Financial Services is the perfect partner when it comes to better
                  commercial loan solutions. Broco Financial Services leverages our large
                  network of financing partners to offer a variety of loans for
                  businesses in the real estate industry. We also have a team of
                  experienced loan officers who understand the real estate
                  industry and have the knowledge and experience to connect you
                  with the right loan solution. Our team is standing by to
                  assist you, so contact us today to learn more about how
                  Broco Financial Services can help you grow your business!
                </p>
                <Link
                  className="btn btn-warning"
                  to="/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Questions?
                </Link>
              </div>

              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg">
                  <img
                    src="/img/loan/real-img4.jpg"
                    alt=""
                    title=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealEstateLoans;
