// <!-- footer -->
import React from "react";
import "./footer.css";

import { Link } from 'react-router-dom';

function footer() {
  return (
    <div>
      <footer>
        <div className="container px-4 text-white">
          <div className="ra"></div>
          <div className="row gx-5">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-1" >
                <div className="ftr-content">
                <Link to="/" style={{textDecoration:'none'}}>
                  <h6 >
                   
                    Broco
                    <br />Solution
                    <br /> Centre
                   
                  </h6>
                  

                  <p>an affiliate of</p>
                  </Link> 
                </div>
                
                <div className="ftr-logo mb-2 ">
                <Link to="/">
                  <img
                    src="img/navber-logo1.png"
                    className="ra-footerimg"
                    alt="logo"
                
                  />
                  </Link>
                </div>
                

                <div className="ftr-social">
                  <i className="fa-brands fa-facebook-f"></i>
                  <i className="fa-brands fa-x-twitter"></i>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="footer-1">
                <div className="ftr-heading">
                  <h3>Quick Links</h3>
                </div>

                <div className="ftr-nav">
                  <nav className="navbar navbar-expand-lg">
                    <div className="collapse d-block navbar-collapse" id="  ">
                      <ul className="navbar-nav d-block ">
                        <li className="nav-item">
                          <a
                            className="nav-link active text-white"
                            aria-current="page"
                            href="/"
                          >
                            Home
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link text-white" href="/BusinessLoans">
                            Business Loans
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link text-white" href="/PersonalCreditCard">
                            Personal Loans
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            aria-disabled="true"
                            href="/BuildingaHome"
                           
                          >
                            Home Loans
                          </a>
                        </li>
                        

                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            aria-disabled="true"
                            href="/about"
                          >
                            Solution Center
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            aria-disabled="true"
                            href="/blog"
                          >
                            Blog
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            aria-disabled="true"
                            href="/about"
                            
                          >
                            About Us
                          </a>
                        </li>
                       
                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            aria-disabled="true"
                            href="/contact"
                          >
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-1">
                <div className="ftr-heading">
                  <h3>Get In Touch With Us</h3>
                </div>

                <div className="ftr-contact pt-3">
                  <div className="location d-flex">
                    <i className="fa-solid fa-location-dot"></i>
                    <a
                      className="text-white ps-2 mt-10"
                      href="https://maps.app.goo.gl/6o5svMXkn4iZcdWw5"
                    >
                      1840 N Atherton Street State college PA 16803
                    </a>
                  </div>

                  <div className="location d-flex py-4">
                    <i className="fa-solid fa-phone-volume"></i>
                    <a className="text-white ps-2" href="tel:838-243-3327">
                      838-243-3327
                    </a>
                  </div>

                  <div className="location d-flex">
                    <i className="fa-solid fa-envelope"></i>
                    <a className="text-white ps-2">info@broco.com</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="footer-1">
                <div className="ftr-nav-ul">
                  <ul>
                    {/* <li><a href="#">Commercial Loan <span>|</span></a></li>
                  <li><a href="#">Business Loan <span>|</span></a> </li>
                  <li><a href="#"> Investment Loan <span>|</span></a></li>
                  <li><a href="#">Commercial <span>|</span></a></li>
                  <li><a href="#">Commercial <span>|</span></a></li>
                  <li><a href="#">Commercial <span>|</span></a></li>
                  <li><a href="#">Commercial <span>|</span></a></li>
                  <li><a href="#">Commercial <span>|</span></a></li>
                  <li><a href="#">Commercial <span>|</span></a></li>
                  <li><a href="#">Commercial <span>|</span></a></li>
                  <li><a href="#">Commercial <span>|</span></a></li>
                  <li><a href="#">Commercial <span>|</span></a></li> */}
                    <li>
                      Commercial Loan | Business Loan | Investment Loan | Fix n
                      flip | Fix n rent | Residential Loan | Mortgage | Business
                      Loan | Business funding | Start Up | Equipment Loan |
                      Equipment Finance | Merchant Cash Advance | MCA | Business
                      Lines of Credit | Real Estate Loan | Financing | SBA |
                      Credit Card | Personal Loan | COnstruction
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          

          <div className="row pt-2">
            <div className="col-md-7">
              <div className="copy">
                <h4>© 2024 Copyrights. All rights reserved by Broco Financial</h4>
                <p>Design & Developed by<a href="https://thewebaid.com/">{" "}EHT Webaid Pvt Ltd.</a></p>
              </div>
            </div>

            {/* <div className="col-4">
              <div className="ftr-nav-ul text-center">
                <ul>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Terms</a>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="col-md-5">
              <div className="copy">
                <h5> Privacy Policy | Terms
                </h5>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default footer;
