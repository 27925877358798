import React from "react";
import "./allLone.css";

function Construction() {
  return (
    <div>
      <div className="ra-con">
        {/* <Background bgImage={bgImage} /> */}

        <div
          className="con-img">
          <div className="con-text">
            <div className="container">
              <div className="con-hed">
                <h1>
                  R.E. Rehab /
                  <br />
                  Construction
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          style={{
            backgroundColor: "#0A0A0A",
          }}
        >
          <div className="constantlygrowing-section area-padding pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 text-center">
                  <h2 className="pb-3" style={{ color: "#FF0202" }}>
                    Commercial Real Estate Is A Huge Market That Is Constantly
                    Growing And Changing
                  </h2>
                  <p style={{ color: "#FFF" }}>
                    At Broco Financial Services, our goal is to provide commercial loan
                    solutions that work for investors across the country. We
                    want to help small businesses get the start they need to
                    grow and succeed. Whether it’s a business line of credit,
                    startup loan, or equipment financing, we can help offer
                    viable options to entrepreneurs, new companies, and more!
                  </p>
                  <a
                    className="btn btn-warning"
                    href="https://app.lendingwise.com/HMLOWebForm.php?bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e"
                    target="_self"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="realestate-section realestate-section2 area-padding">
            <div className="container">
              <div className="row">
                <div className="col pb-5 commercialbox">
                  <div className="commercial-section commercial-section2">
                    <div className="commercial-icon">
                      <img
                        className
                        src="/img/loan/con-logo1.png"
                        alt=""
                        title
                      />
                    </div>
                    <h2>Fix n Flip</h2>
                    <p>
                      Loans for investors who are buying distressed properties,
                      rehabbing, and selling. Flexible and Fast.{" "}
                    </p>
                    <a
                      href="https://app.lendingwise.com/HMLOWebForm.php?bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e"
                      className="btn btn-warning"
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
                <div className="col pb-5 commercialbox">
                  <div className="commercial-section commercial-section2">
                    <div className="commercial-icon">
                      <img
                        className
                        src="/img/loan/con-logo2.png"
                        alt=""
                        title
                      />
                    </div>
                    <h2>Fix n Rent</h2>
                    <p>
                      Loans for investors looking to buy, rehab and hold as
                      rental properties. Buy, Renovate, Rent, Refinance, Repeat!{" "}
                    </p>
                    <a
                      href="https://app.lendingwise.com/HMLOWebForm.php?bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e"
                      className="btn btn-warning"
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
                <div className="col pb-5 commercialbox">
                  <div className="commercial-section commercial-section2">
                    <div className="commercial-icon">
                      <img
                        className
                        src="/img/loan/con-logo3.png"
                        alt=""
                        title
                      />
                    </div>
                    <h2>bridge</h2>
                    <p>
                      Interim financing for investment properties where a quick
                      close is necessary.{" "}
                    </p>
                    <a
                      href="https://app.lendingwise.com/HMLOWebForm.php?bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e"
                      className="btn btn-warning"
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
                <div className="col pb-5 commercialbox">
                  <div className="commercial-section commercial-section2">
                    <div className="commercial-icon">
                      <img
                        className
                        src="/img/loan/con-logo4.png"
                        alt=""
                        title
                      />
                    </div>
                    <h2>ground up construction</h2>
                    <p>
                      We can help you fund your new construction. This is ideal
                      for major construction projects.{" "}
                    </p>
                    <a
                      href="https://app.lendingwise.com/HMLOWebForm.php?bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e"
                      className="btn btn-warning"
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
                <div className="col pb-5 commercialbox">
                  <div className="commercial-section commercial-section2">
                    <div className="commercial-icon">
                      <img
                        className
                        src="/img/loan/con-logo5.png"
                        alt=""
                        title
                      />
                    </div>
                    <h2>spec &amp; model homes</h2>
                    <p>
                      Ideal for construction companies and developers who need
                      to build spec and model homes for sale and showing.{" "}
                    </p>
                    <a
                      href="https://app.lendingwise.com/HMLOWebForm.php?bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e"
                      className="btn btn-warning"
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="constantlygrowing-section area-padding">
          <div className="container">
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel"> */}
          {/* <div className="carousel-inner">
                <div className="carousel-item active">
                  <img className="d-block w-100 img-fluid" src="https://www.cheddarr.com/wp-content/uploads/2022/11/6-1.jpg" alt="" />
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100 img-fluid" src="https://www.cheddarr.com/wp-content/uploads/2022/11/5-1.jpg" alt="" />
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100 img-fluid" src="https://www.cheddarr.com/wp-content/uploads/2022/11/2-1.jpg" alt="" />
                </div>
              </div> */}
          {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div> */}
          <div className="merchantadvance-section area-padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-12 pt-5">
                  <h3 style={{ color: "#FFF" }}>
                    What Is a{" "}
                    <span style={{ color: "#FF0202" }}>
                      Commercial Mortgage?
                    </span>
                  </h3>
                  <p style={{ color: "#FFF" }}>
                    A commercial mortgage is a loan used to purchase Commercial
                    Real Estate. The property that the loan is used to purchase
                    must be for business purposes and not for personal use.
                    Commercial mortgages are typically taken out by businesses
                    to expand, upgrade, or renovate their facilities.
                  </p>
                  <a
                    href="/contact"
                    className="btn btn-warning"
                  >
                    Questions?
                  </a>
                </div>
                <div className="col-lg-5 col-md-5 col-12">
                  <div className="cashadvanceimg">
                    <img
                      className="img-fluid"
                      src="/img/loan/con-1.png"
                      alt=""
                      title
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="requirements-section area-padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="requirementsbox">
                    <div className="requirements-img">
                      <img
                        className="img-fluid"
                        src="/img/loan/con-2.png"
                        alt=""
                        title
                      />
                    </div>
                    <h3 style={{ color: "#FF0202" }}>
                      How Can You Use a Commercial Mortgage?
                    </h3>
                    <p />
                    <p>
                      There are a few ways that you can use a Commercial
                      Mortgage:
                    </p>
                    <ul>
                      <li>To purchase Commercial Real Estate</li>
                      <li>
                        To refinance an existing Commercial Real Estate mortgage
                      </li>
                      <li>
                        To take out additional money against the equity of your
                        Commercial Real Estate
                      </li>
                    </ul>
                    <p />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="requirementsbox">
                    <div className="requirements-img">
                      <img
                        className
                        src="/img/loan/con-3.png"
                        alt=""
                        title
                      />
                    </div>
                    <h3 style={{ color: "#FF0202" }}>
                      What Are the Requirements for a Commercial Mortgage?
                    </h3>
                    <p />
                    <p>
                      In order to qualify for a commercial mortgage, you will
                      need to have a good credit score and demonstrate that you
                      have been in business for a few years. You will also need
                      to provide documents such as bank statements, tax returns,
                      and information about the property that you plan to
                      purchase.
                    </p>
                    <p />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="qualifymerchant-section area-padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-4 col-12">
                  <div className="qualifymerchant-img">
                    <img
                      src="/img/loan/con-4.png"
                      className
                      alt=""
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="col-lg-7 col-md-8 col-12">
                  <h3 style={{ color: "#FF0202" }}>
                    Why Should I Use Broco Financial Services?
                  </h3>
                  <p style={{ color: "#FFF" }}>
                    At Broco Financial Services, we pride ourselves on our ability to offer
                    commercial loan solutions that just make sense. We have a
                    wide network of partners across the country that we work
                    with to help small businesses get the financing they need.
                    We also offer a variety of resources to help you navigate
                    the commercial lending process.
                  </p>
                  <a
                    href="/contact"
                    className="btn btn-warning"
                  >
                    Questions?
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="requirements-section area-padding text-center">
            <div className="container">
              <div className="helps-text">
                <h2 style={{ color: "#FF0202" }}>
                  Broco Financial Services Helps To Ensure A Roof For Your Small Business
                </h2>
                <p />
                <p style={{ color: "#FFF" }}>
                  Commercial real estate is a significant part of the US
                  economy, with over $11 trillion in assets and growing. In
                  order to have a successful small business, you need to have
                  access to capital, and one way to get that is through
                  commercial real estate loans. Commercial real estate loans are
                  mortgages used to finance the purchase or refinance of
                  income-producing commercial property, such as office
                  buildings, retail centers, warehouses, and hotels.
                </p>
                <p style={{ color: "#FFF" }}>
                  There are many different types of commercial real estate loans
                  available, each with its own set of terms and conditions. The
                  most common type of loan is the traditional bank loan, which
                  is typically given for a term of five to 25 years. However,
                  there are also SBA-backed loans, mezzanine loans, and private
                  money loans.
                </p>
                <p style={{ color: "#FFF" }}>
                  {" "}
                  No matter what type of loan you decide to go with, it’s
                  important to do your research and compare interest rates,
                  terms, and loan amounts. You also need to make sure the
                  property you’re buying is in a good location and has a solid
                  rental history or future.
                </p>
                <p />
                <a
                  href="/contact"
                  className="btn btn-warning"
                >
                  Questions?
                </a>
              </div>
            </div>
          </div>
          <div className="merchantadvance-section area-padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-12">
                  <h3 style={{ color: "#FF0202" }}>The Bottom Line</h3>
                  <p />
                  <p style={{ color: "#FFF" }}>
                    Commercial real estate is a large, dynamic market that has
                    continued to grow. At Broco Financial Services, our goal is to provide
                    commercial loan solutions that work. We want to assist small
                    companies in getting the start they need to develop and
                    flourish.
                  </p>
                  <p />
                  <a
                    href="/contact"
                    className="btn btn-warning"
                  >
                    Questions?
                  </a>
                </div>
                <div className="col-lg-5 col-md-5 col-12">
                  <div className="cashadvanceimg">
                    <img
                      sclas
                      src="/img/loan/con-img1.jpg"
                      alt=""
                      title
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Construction;
