import React from "react";
import "./allLone.css";

function SBA() {
  return (
    <div>
      <div className="ra-con">
        {/* <Background bgImage={bgImage} /> */}

        <div
          className="con-img">
          <div className="con-text">
            <div className="container">
              <div className="con-hed">
                <h1>
                  SBA
                  <br />
                  Loans
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingBottom: "40px",
          backgroundColor: "#0A0A0A",
          paddingTop: "8%",
        }}
      >
        <div className="constantlygrowing-section area-padding text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <h2 />
                <p style={{ color: "#FFF" }}>
                  SBA BUSINESS LOANS When you have a great business idea, your
                  next step is to figure out how to finance it. Small Business
                  Administration loans or SBA are a great way to get the funding
                  you need to start or grow your business. The lending team at
                  Broco Financial Services helps small businesses find funding so they can get
                  the start they need. Our goal is to provide the business
                  lending solutions you need to succeed. Along with SBA loans,
                  we also provide business lines of credit, startup loans,
                  equipment financing, and more. Learn about our loan types and
                  contact us today to get a quote!
                </p>
                <a
                  className="btn btn-warning"
                  href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=lo"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section area-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/sba-img1.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  What Is A Small Business Administration Loan?{" "}
                </h3>
                <p style={{ color: "#FFF" }}>
                  The Small Business Administration (SBA) is a government agency
                  that offers financial assistance to small businesses. The SBA
                  does not simply lend money directly to the small businesses,
                  but it does guarantee loans made by private lenders. This
                  guarantee makes it much easier for small businesses to get
                  loans from banks and other private lenders. Small Business
                  Administration loans are drafted in straight agreement between
                  lenders and the SBA agencies. Borrowers use these programs
                  when finding lenders for their small businesses. Lenders
                  appreciate the SBA loan programs because it shoulders some of
                  the risk for the lender by directly guaranteeing a portion of
                  the loan amount. Because of that loan guarantee, lenders can
                  offer more flexible payment terms and lower the interest rates
                  than most small businesses would otherwise be able to get.{" "}
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantadvance-section area-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  Types Of SBA Business Loans{" "}
                </h3>
                <p style={{ color: "#FFF" }}>
                  The SBA offers different types of loans, each with its own
                  eligibility requirements and terms. The most common type of
                  loan guaranteed by the SBA loan is the 7(a) loan. 7(a) loans
                  are available for different purposes, including working
                  capital, equipment, and real estate. The maximum amount of
                  loan for the 7(a) loan is $5 million. Other types of SBA
                  business loans include: 504 loans - available for the purchase
                  of equipment or real estate. The maximum loan amount for the
                  504 loan is $5 million. Microloans - small loans of up to
                  $50,000 that are available to start-up businesses and
                  businesses with limited operating history. Disaster loans -
                  available to businesses and homeowners in areas affected by a
                  declared disaster.{" "}
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg">
                  <img
                    className
                    src="/img/loan/sba-img2.jpg"
                    alt=""
                    title
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section area-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/sba-img3.png"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  How To Qualify For A Small Business Administration Loan?{" "}
                </h3>
                <p style={{ color: "#FFF" }}>
                  To qualify for an SBA loan, you must first meet the
                  eligibility requirements of the specific loan program you are
                  interested in. In general, you must be a for-profit business
                  with a limited operating history and have a good credit
                  history. You may also need to provide collateral or put up
                  your personal guarantee.
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantadvance-section area-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  Benefits Of A Small Business Administration Loan
                </h3>
                <p style={{ color: "#FFF" }}>
                  There are several benefits of getting a small business loan
                  from the SBA. Benefits include: The SBA guarantee makes it
                  easier for you to get a loan from a bank or other lender. The
                  SBA Business loan offers several types of loans, with terms
                  that may be more favorable than those offered by private
                  lenders. The SBA has resources and programs to help you grow
                  your business. You may be able to use the proceeds from a
                  small business loan for any business purpose. If you are
                  thinking of starting or expanding your business, an SBA loan
                  may be a good option for you. For more information on SBA
                  loans and how to qualify, visit the SBA website or contact a
                  team member at Broco Financial Services.{" "}
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Enquire Now
                </a>
              </div>
              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg">
                  <img
                    className
                    src="/img/loan/sba-img4.jpg"
                    alt=""
                    title
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section area-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/sba-img5.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  Eligibility Requirements For SBA Loan{" "}
                </h3>
                <p style={{ color: "#FFF" }}>
                  The eligibility requirements for an SBA business loan vary
                  depending on which type of loan you are applying for. In
                  general, you must be a for-profit business with limited
                  operating history and have a good credit history. You also
                  need to be a business that is located and operated in the U.S.
                  or its territories. You may also need to provide collateral or
                  put up your personal guarantee. For more information on
                  specific eligibility requirements, contact a lender at
                  Broco Financial Services.
                </p>
                <a
                  className="btn btn-warning"
                  href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="constantlygrowing-section area-padding text-center"
          style={{ backgroundColor: "#0A0A0A" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <p> </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SBA;
