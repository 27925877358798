import React from 'react'

function PurchaseLand() {
  return (
    <div>
    <div className="ra-con">
      {/* <Background bgImage={bgImage} /> */}

      <div
        className="con-img">
        <div className="con-text">
          <div className="container">
            <div className="con-hed">
              <h1>
                Business Acquistion
                <br />
                Franchise
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style={{
        paddingBottom: "40px",
        backgroundColor: "#0A0A0A",
        paddingTop: "5%",
      }}
    >
        <section className="area-padding">
          <div className="container px-4 text-center">
            <div className="row gx-5">
              <div className="col-lg-6 service-page-post m-auto">
                <div className="service-page-content ">
                  <h2 className="x_elementToProof x_ContentPasted5">What is a land loan?</h2>
                  <div className="x_elementToProof x_ContentPasted5"><br aria-hidden="true" />&nbsp;<p />
                    <div className="x_ContentPasted5" style={{color:"#fff"}}>A land loan is a way to finance property that doesn’t have a house built on it. There are several categories of land, and the type you’re considering will likely have an impact on the type of loan you can get:</div>
                    <div />
                    <div className="x_ContentPasted5 x_elementToProof" style={{color:"#fff"}}>1 Raw land: This is land with no improvements (such as water and sewer lines or road access) that would help make the property buildable.</div>
                    <div className="x_ContentPasted5 x_elementToProof" />
                    <div className="x_ContentPasted5 x_elementToProof" style={{color:"#fff"}}>2 Unimproved land: While similar to raw land, unimproved land may have some basic infrastructure or older structures, but generally doesn’t have all of the water, electric and other utilities you’ll need. This land may have once had a house on it, but doesn’t any longer. This may also be referred to as “undeveloped” land, but must typically be conducive to building a home in the future.</div>
                    <div className="x_ContentPasted5 x_elementToProof" />
                  </div>
                  <div className="x_elementToProof x_ContentPasted5" style={{color:"#fff"}}>3 Improved land: This type of land has access to utilities and roads and is ready for building a house. These parcels may also be known as lots.</div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tm-about-us-img">
                  <div className="tm-about-us-img-bg">
                    <img className="img-fluid" src="https://demo.webaiders.com/cheddarr/wp-content/themes/cheddarr/img/about-img-bg.png" alt="" /> 
                  </div>
                  <div className="about-page-image">
                    <img src="./img/loan/securing-funding-1.png"  className="img-fluid" alt="image" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-5 pt-lg-4">
              <div className="col-12">
                <div className="service-page-content">
                  <p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Get-Start-section area-padding text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="Get-section">
                  <div className="tm-sec-title">
                    <h3 >Get Started Now on <br /><span>Your Financial Goals</span></h3>
                  </div>
                  <div className="get-content text-white">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget arcu arcu. Morbi vitae elit rutrum nulla interdum feugiat. 
                      Proin facilisis nulla ut turpis volutpat aliquet.
                      Praesent eget leo sit amet ante varius euismod.</p>
                  </div>
                  <div className="d-flex tm-header-btn justify-content-center">
                    <a href="/contact"><button className="btn tm-header-btn1" type="submit">
                        <span>Apply For Home Purchase</span>
                      </button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default PurchaseLand
