import React from "react";
import "./allLone.css";

function EquipmentFinancing() {
  return (
    <div>
      <div className="ra-con">
        {/* <Background bgImage={bgImage} /> */}

        <div
          className="con-img">
          <div className="con-text">
            <div className="container">
              <div className="con-hed">
                <h1>
                  Equipment
                  <br />
                  Financing
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingBottom: "40px",
          backgroundColor: "#0A0A0A",
          paddingTop: "8%",
        }}
      >
        <div className="realestate-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12 pb-5">
                <div className="commercial-section">
                  <div className="commercial-icon">
                    <img
                      className
                      src=""
                      alt=""
                      title
                    />
                  </div>
                  <h2>business equipment</h2>
                  <p>
                    Use for vital equipment investments needed for your business
                    to grow!{" "}
                  </p>
                  <a
                    className="btn btn-warning"
                    href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                    target="_blank"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12 pb-5">
                <div className="commercial-section">
                  <div className="commercial-icon">
                    <img
                      className
                      src="https://www.cheddarr.com/wp-content/uploads/2022/11/HeavyEqu.png"
                      alt=""
                      title
                    />
                  </div>
                  <h2>heavy equipment</h2>
                  <p>
                    Yellow Iron, non-titled vehicles and manufacturing equipment
                    needed for industry growth.{" "}
                  </p>
                  <a
                    className="btn btn-warning"
                    href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                    target="_blank"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12 pb-5">
                <div className="commercial-section">
                  <div className="commercial-icon">
                    <img
                      className
                      src="https://www.cheddarr.com/wp-content/uploads/2022/11/Untitled-design-29.png"
                      alt=""
                      title
                    />
                  </div>
                  <h2>truck and transportation</h2>
                  <p>Truck financing for short and long hauls. </p>
                  <a
                    className="btn btn-warning"
                    href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                    target="_blank"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="constantlygrowing-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <h2 />
                <p style={{ color: "#FFF" }}>
                  Furnishing your business with the equipment you need in order
                  to function well and efficiently isn’t always as cut and dry
                  as it may seem. From the obvious things you’ll need, like a
                  point-of-sale system to process transactions, to the less
                  obvious things, like a break room microwave for your
                  employees, you’d probably be pleasantly surprised as to what
                  kinds of equipment are covered by business equipment loans!{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/eq-ing1.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3>
                  <span style={{ color: "#FF0202" }}>Equipment Financing</span>
                </h3>
                <p style={{ color: "#FFF" }}>
                  Equipment financing is the term used to describe a loan or
                  financing program that a business owner uses to purchase and
                  obtain items that are needed to run their business smoothly
                  and efficiently. This could include loans, financing or
                  payment plans, or even business credit cards. In particular,
                  we’re covering loans specifically designed to cover equipment
                  costs for business owners like you.
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_blank"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantadvance-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3>
                  <span style={{ color: "#FF0202" }}>
                    How Do You Qualify For An Equipment Loan?
                  </span>
                </h3>
                <p style={{ color: "#FFF" }}>
                  Qualifying for an equipment loan usually has a few
                  requirements. Typically, you’ll need to have been in business
                  for at least a year, and have a certain threshold of revenue
                  to meet. Most places require about $50,000 in annual revenue.
                  Your credit score will also come into play, as well, and
                  depending on your cash flow and revenues, you will likely need
                  to score higher than 650 to qualify. There are also
                  considerations made for businesses who don’t meet these
                  requirements, so always be sure to ask questions and learn
                  more from a potential lender!{" "}
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_blank"
                >
                  Apply Now
                </a>
              </div>
              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg">
                  <img
                    className
                    src="/img/loan/eq-ing2.jpg"
                    alt=""
                    title
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/eq-ing3.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3>
                  <span style={{ color: "#FF0202" }}>
                    Is Financing Equipment A Good Idea?
                  </span>
                </h3>
                <p style={{ color: "#FFF" }}>
                  In short, yes! While there are some people who recommend only
                  purchasing things when you have the liquid cash for them,
                  there are some situations where that just isn’t plausible.
                  Instead of draining your business bank account, or worse, your
                  personal bank account, for that item you need, financing is
                  generally a better option. Not only is financing a great
                  option to get the things you need without draining your liquid
                  cash, it can also help improve your credit score, as the more
                  payments you make on time, the better your credit history will
                  be.
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_blank"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantadvance-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3>
                  <span style={{ color: "#FF0202" }}>
                    What Kind Of Equipment Can I Finance?
                  </span>
                </h3>
                <p style={{ color: "#FFF" }}>
                  There are many different definitions of business equipment and
                  regardless of whether you need big or small items for your
                  business, you can likely purchase it with financing. The range
                  of equipment can be from construction equipment, like tractors
                  and forklifts, but you can also get as small as office
                  appliances and fixtures, like rugs, coffee makers, lighting,
                  and more. If it helps to keep your business running smoothly
                  and create a better environment for your employees, it’s
                  likely eligible for financing.{" "}
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_blank"
                >
                  Apply Now
                </a>
              </div>
              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg">
                  <img
                    className
                    src="/img/loan/eq-ing4.jpg"
                    alt=""
                    title
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="constantlygrowing-section pt-5 text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <p style={{ color: "#FFF" }}>
                  If your business needs a little boost to get the equipment you
                  need to run smoothly and efficiently, don’t wait! Get started
                  with your business equipment loan with Broco Financial Services today by
                  reaching out to us. Learn more about these loans and other
                  loans, too, by browsing our site.{" "}
                </p>
                <a
                  className="btn btn-warning"
                  href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                  target="_blank"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EquipmentFinancing;
