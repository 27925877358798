import React from 'react'

function BuildingaHome() {
  return (
    <div>

<div className="ra-con">
        {/* <Background bgImage={bgImage} /> */}

        <div
          className="con-img">
          <div className="con-text">
            <div className="container">
              <div className="con-hed">
                <h1>
                  Business Acquistion
                  <br />
                  Franchise
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingBottom: "40px",
          backgroundColor: "#0A0A0A",
          paddingTop: "8%",
        }}
      >
        <section className="area-padding">
          <div className="container px-4 text-center">
            <div className="row gx-5">
              <div className="col-lg-6 service-page-post m-auto">
                <div className="service-page-content ">
                  <p >A construction loan is a short-term loan that covers only the costs of custom home building. This is different from a mortgage, and it’s considered specialty financing. Once the home is built, the prospective occupant must apply for a mortgage to pay for the completed home. While we don’t finance construction loans, we can help you when it comes time to convert this to a permanent mortgage.</p>
                  <p >A home construction loan is a short-term, higher-interest loan that provides the funds required to build a residential property.</p>
                  <p >Construction loans typically are one year in duration. During this time, the property must be built and a certificate of occupancy should be issued.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tm-about-us-img" >
                  <div className="tm-about-us-img-bg">
                    <img className="img-fluid" src="https://demo.webaiders.com/cheddarr/wp-content/themes/cheddarr/img/about-img-bg.png" alt="" /> 
                  </div>
                  <div className="about-page-image">
                    <img src="./img/loan/securing-funding-1.png" className="img-fluid" alt="image" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-5 pt-lg-4">
              <div className="col-12">
                <div className="service-page-content">
                  <p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Get-Start-section area-padding text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="Get-section">
                  <div className="tm-sec-title">
                    <h3 >Get Started Now on <br /><span>Your Financial Goals</span></h3>
                  </div>
                  <div className="get-content text-white">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget arcu arcu. Morbi vitae elit rutrum nulla interdum feugiat. 
                      Proin facilisis nulla ut turpis volutpat aliquet.
                      Praesent eget leo sit amet ante varius euismod.</p>
                  </div>
                  <div className="d-flex tm-header-btn justify-content-center">
                    <a href="/contact"><button className="btn tm-header-btn1" type="submit">
                        <span>Apply For Home Purchase</span>
                      </button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default BuildingaHome
