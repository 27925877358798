import { useState, useEffect } from "react";
import "./blog.css";

// import axios from "axios";
// import { error } from "three";

const Blog = () => {
  const [Posts, setPosts] = useState({});
  const [Recent, setRecent] = useState([]);
  const [Banner, setBanner] = useState({});

  useEffect(() => {
    fetch("https://brocofinancial.com/backend/public/api/blogBanner")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        if (data.status) {
          setBanner(data);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch("https://brocofinancial.com/backend/public/api/Recentblog")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        if (data.status) {
          setRecent(data);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch("https://brocofinancial.com/backend/public/api/allblog")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          setPosts(data);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const { data, path } = Banner;
  let data1 = data ? data.blogbanner : "";
  let Posts2 = Posts.data ? Posts.data : [];
  let Recent2 = Recent.data ? Recent.data : [];

  console.log(Posts);

  return (
    <div>
      <div className="ra-blog-banner">
        <div className="">
          {/* <img src={`${path}/${data1}`} alt="" />  */}
        </div>
        <div className="blog-text">
          <div className="container">
            <div className="blog-hed">
              <h1>
                Blogs &
                <br />
                Stories
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="ra-blog">
        <div className="container">
          <h2
            style={{
              fontSize: "35px",
              fontWeight: "700",
              color: "white",
              padding: "45px 0px 10px 0px",
            }}
          >
            Recent Blog Posts
          </h2>
          <div className="row gy-4 posts-list">
            {Recent2.map((d2, index) => (
              <div key={index} className="col-md-6">
                {/* <div>{data.id}</div>  */}
                <div>
                  <div className="ra-post-img">
                    <img
                      src={`${Recent.path}/${d2.image}`}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="ra-title-blog">
                    <p>
                      {d2.blogtitle} .{" "}
                      <time dateTime="2022-01-01"> {d2.publishedDate}</time>
                    </p>

                    <h2>
                      <a href="#">{d2.blogtitle}</a>
                    </h2>

                    <p>{d2.details}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <section className="blog">
          <div className="container">
            <h2
              style={{
                fontSize: "35px",
                fontWeight: "700",
                color: "white",
                padding: "45px 0px 10px 0px",
              }}
            >
              All Blog Posts
            </h2>
            <div className="row gy-4 posts-list">
            {Posts2.map((d2, index) => (
                <div key={index} className="col-md-4">
                  <article>
                    <div className="post-img">
                      <img
                        src={`${Posts.path}/${d2.image}`}
                        alt=""
                        style={{ width: "100%" , height:"237px",}}
                      />
                    </div>
                    <p className="post-author">
                      {d2.publisher} .
                      <time dateTime="2022-01-01"> {d2.publishedDate}</time>
                    </p>
                    <h2 className="title">
                      <a href="blog-details.html">{d2.blogtitle} </a>
                    </h2>
                    <div className="ra-title">
                      <p>{d2.details}</p>
                    </div>
                  </article>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Blog;
