// import logo from './logo.svg';
import "./App.css";
// import "./Home.css";
// import "./footer.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./component/navber";
import Home from "./component/home";
import Loan from "./component/loan";
import Footer from "./component/footer";
import Contact from "./component/contact";
import Blog from "./component/blog";
import About from "./component/about";
import RealEstateLoans from "../src/business/RealEstateLoans";
import Construction from "./business/Construction";
import EquipmentFinancing from "./business/EquipmentFinancing";
import Startup from "./business/Startup";
import USDA from "./business/USDA";
import SBA from "./business/SBA";
import ShortTerm from "./business/ShortTerm";
import BusinessLineOfCredit from "./business/BusinessLineOfCredit";
import BusinessAcquistionFranchise from "./business/BusinessAcquistion&Franchise";
import Financing from "./business/Financing";
import MerchantCashAdvance from "./business/MerchantCashAdvance";
import BusinessLoans from "./business/BusinessLoans";
import BuildingaHome from "./HomeLoan/BuildingaHome";
import PurchaseaHome from "./HomeLoan/PurchaseaHome";
import PurchaseLand from "./HomeLoan/PurchaseLand";
import RefinanceaHome from "./HomeLoan/RefinanceaHome";
import Reverseamortgage from "./HomeLoan/Reverseamortgage";
import PersonalCreditCard from "./Personal Loan/PersonalCreditCard";
import PersonalLineofCredit from "./Personal Loan/PersonalLineofCredit";
import PersonalTermLoan from "./Personal Loan/PersonalTermLoan";
import PartnerSign from "./SolutionCenter/PartnerSign";
import Partnerlogin from "./SolutionCenter/partnerlogin";

import ReactDOM from "react-dom";



function App() {
  return (
    <div>

    <Router>
      <Navbar />
            <Routes>
          
          <Route path="/" element={<Home />} />
          <Route path="/loan" element={<Loan />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/RealEstateLoans" element={<RealEstateLoans />} />
          <Route path="/Construction" element={<Construction />} />
          <Route path="/EquipmentFinancing" element={<EquipmentFinancing/>} />
          <Route path="/Startup" element={<Startup/>} />
          <Route path="/USDA" element={<USDA/>} />
          <Route path="/SBA" element={<SBA/>} />
          <Route path="/ShortTerm" element={<ShortTerm/>} />
          <Route path="/BusinessLineOfCredit" element={<BusinessLineOfCredit/>} />
          <Route path="/BusinessAcquistionFranchise" element={<BusinessAcquistionFranchise/>} />
          <Route path="/Financing" element={<Financing/>} />
          <Route path="/MerchantCashAdvance" element={<MerchantCashAdvance/>} />
          <Route path="/BusinessLoans" element={<BusinessLoans/>} />
          <Route path="/BuildingaHome" element={<BuildingaHome/>} />
          <Route path="/PurchaseaHome" element={<PurchaseaHome/>} />
          <Route path="/PurchaseLand" element={<PurchaseLand/>} />
          <Route path="/RefinanceaHome" element={<RefinanceaHome/>} />
          <Route path="/Reverseamortgage" element={<Reverseamortgage/>} />
          <Route path="/PersonalCreditCard" element={<PersonalCreditCard/>} />
          <Route path="/PersonalLineofCredit" element={<PersonalLineofCredit/>} />
          <Route path="/PersonalTermLoan" element={<PersonalTermLoan/>} />
          <Route path="/PartnerSign" element={<PartnerSign/>} />
          <Route path="/Partnerlogin" element={<Partnerlogin/>} />

            </Routes>
            <Footer />
        </Router>
    </div>
  );
}

export default App;


const rootElement = document.getElementById("root");
ReactDOM.render(<Contact />, rootElement); // change the component name to Contact