import { useState, useEffect } from "react";
import "./Home.css";



function Loan() {



    const [loan, setLoan] = useState([]);




    useEffect(() => {
      fetch("https://brocofinancial.com/backend/public/api/homeService")
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status) {
            setLoan(data.data);
          } else {
            console.log("No data or empty array received from the API.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);



    let loan2 = loan.data? loan.data:[];
    // console.log(data);



  return (
    <div>
      <div className="ra-blog-banner">
        <div
          className="blog-img"
          style={{
            backgroundImage: "url('img/blog-banner.jpg')",
            height: "500px",
          }}
        >
          <div className="blog-text">
            <div className="container">
              <div className="blog-hed">
                <h1>
                  Blogs &
                  <br />
                  Stories
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="mainlone">
        <div className="container">
        

                
          <div className="ra-lone">
            <div>
              <h2
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontSize: "38px",
                  fontWeight: "700",
                }}
              >
                Service We offer
              </h2>
            </div>
          </div>
          <div className="row">
          {loan2.map((d2,index) => (
              <div key={index} className="col-md-4" >
                {/* <div>{data.id}</div> */}
            
              <div className="card">
              <img src={`${loan.path}/${d2.image}`}
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="ra-card1">
                  <div className="container">
                    <p
                      className="title"
                      style={{
                        paddingTop: "10px",
                        fontSize: "20px",
                        fontWeight: "700",
                        margin: "0",
                        color: "#FF0202",
                      }}
                    >
                      {d2.bussinessname}
                    </p>
                    <p
                      style={{
                        fontSize: "16px",

                        lineHeight: "1.3rem",
                        paddingBottom: "16px",
                        color: "#fff",
                        paddingRight: "10.5",
                      }}
                      st
                    >
                     {d2.description}
                    </p>
                  </div>
                </div>
              </div>
            

            </div>
        ))}
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loan
