import React from "react";

function PersonalTermLoan() {
  return (
    <div>
      <div className="ra-con">
        {/* <Background bgImage={bgImage} /> */}

        <div
          className="con-img">
          <div className="con-text">
            <div className="container">
              <div className="con-hed">
                <h1>
                  Personal Term
                  <br />
                  Loans
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingBottom: "40px",
          backgroundColor: "#0A0A0A",
          paddingTop: "8%",
        }}
      >
        <section className="area-padding">
          <div className="container px-4 text-center">
            <div className="row gx-5">
              <div className="col-lg-6 service-page-post m-auto">
                <div className="service-page-content ">
                  <p>
                    <em>
                      We offer you a range of options in finding the right
                      personal loan for your personal needs.{" "}
                    </em>
                    A personal loan permits you to borrow money from a lender
                    for any purpose, usually with a fixed term, interest rate,
                    and a regular monthly payment schedule. Collateral is
                    usually not required and these personal loans typically have
                    very low interest rates compared to most credit cards. Be it{" "}
                    <strong>personal loans for bad credit</strong> or{" "}
                    <strong>low interest personal loans in the US</strong>, get
                    the funds you need to make things happen now!
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tm-about-us-img">
                  <div className="tm-about-us-img-bg">
                    <img
                      className="img-fluid"
                      src="https://demo.webaiders.com/cheddarr/wp-content/themes/cheddarr/img/about-img-bg.png"
                      alt=""
                    />
                  </div>
                  <div className="about-page-image">
                    <img
                      src="./img/loan/securing-funding-1.png" 
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-5 pt-lg-4">
              <div className="col-12">
                <div className="service-page-content">
                  <p></p>
                  <p>
                    What is Lorem Ipsum?
                    <br />
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry’s
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                  <p>
                    Why do we use it?
                    <br />
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using ‘Content here, content here’, making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for ‘lorem ipsum’ will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  <p>
                    Where does it come from?
                    <br />
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words,
                    consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered
                    the undoubtable source. Lorem Ipsum comes from sections
                    1.10.32 and 1.10.33 of “de Finibus Bonorum et Malorum” (The
                    Extremes of Good and Evil) by Cicero, written in 45 BC. This
                    book is a treatise on the theory of ethics, very popular
                    during the Renaissance. The first line of Lorem Ipsum,
                    “Lorem ipsum dolor sit amet..”, comes from a line in section
                    1.10.32.
                  </p>
                  <p>
                    The standard chunk of Lorem Ipsum used since the 1500s is
                    reproduced below for those interested. Sections 1.10.32 and
                    1.10.33 from “de Finibus Bonorum et Malorum” by Cicero are
                    also reproduced in their exact original form, accompanied by
                    English versions from the 1914 translation by H. Rackham.
                  </p>
                  <p />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Get-Start-section area-padding text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="Get-section">
                  <div className="tm-sec-title">
                    <h3>
                      Get Started Now on <br />
                      <span>Your Financial Goals</span>
                    </h3>
                  </div>
                  <div className="get-content text-white">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Quisque eget arcu arcu. Morbi vitae elit rutrum nulla
                      interdum feugiat. Proin facilisis nulla ut turpis volutpat
                      aliquet. Praesent eget leo sit amet ante varius euismod.
                    </p>
                  </div>
                  <div className="d-flex tm-header-btn justify-content-center">
                    <a href="/contact">
                      <button className="btn tm-header-btn1" type="submit">
                        <span>Apply For Home Purchase</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default PersonalTermLoan;
