import React from "react";
import "./allLone.css";

function USDA() {
  return (
    <div>
      <div className="ra-con">
        {/* <Background bgImage={bgImage} /> */}

        <div
          className="con-img">
          <div className="con-text">
            <div className="container">
              <div className="con-hed">
                <h1>
                  USDA
                  <br />
                  Loans
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingBottom: "40px",
          backgroundColor: "#0A0A0A",
          paddingTop: "8%",
        }}
      >
        <div className="constantlygrowing-section area-padding text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <h2 className="pb-4" style={{ color: "#FF0202" }}>
                  Explore Our USDA Loan Information
                </h2>
                <p style={{ color: "#FFF" }}>
                  The USDA Business Industry Loan Program is a government-backed
                  loan program designed to help credit-worthy rural businesses
                  obtain needed capital to grow and create jobs in their local
                  communities. The program bolsters the availability of private
                  credit by guaranteeing loans for businesses in rural areas
                  across the US.
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                >
                  Questions?
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default USDA;
