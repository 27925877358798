import React from "react";
import "./allLone.css";

function Startup() {
  return (
    <div>
      <div className="ra-con">
        {/* <Background bgImage={bgImage} /> */}

        <div
          className="con-img">
          <div className="con-text">
            <div className="container">
              <div className="con-hed">
                <h1>
                  Startup
                  <br />
                  Loan
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingBottom: "40px",
          backgroundColor: "#0A0A0A",
          paddingTop: "8%",
        }}
      >
        <div className="constantlygrowing-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                {/* <h2 /> */}
                <p style={{ color: "#FFF" }}>
                  You’ve got the next game-changing, industry-disrupting idea,
                  but starting your own business comes with a lot of costs.
                  Startup loans are specifically designed to help ambitious
                  individuals get their businesses up and running. If you’re
                  ready to start the next big thing, get in touch with Broco Financial Services
                  to secure the best startup loan for your business.
                </p>
                <a
                  className="btn btn-warning"
                  href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/start-img1.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3 style={{ color: "#FF0202" }}>What Is A Startup Loan?</h3>
                <p style={{ color: "#FFF" }}>
                  Starting a business from scratch requires a lot of work and a
                  lot of funding. Too often, startup owners find themselves
                  handing out equity to investors or borrowing money from
                  friends and family to get the initial capital they need.
                  Startup loans are a better way to finance your business and
                  accelerate its growth.
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Questions?
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantadvance-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  Are Loans A Good Way To Fund Your Startup?
                </h3>
                <p style={{ color: "#FFF" }}>
                  Startup loans are an incredibly effective way to fund your
                  startup. These loans can provide you with the capital you need
                  to hire essential employees, purchase inventory or equipment,
                  move into your first office space, and more, all while
                  maintaining your equity in your company. Don’t hand out equity
                  to investors so they can get paid for your backbreaking work.
                  Apply for a startup loan with Broco Financial Services today!{" "}
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Questions?
                </a>
              </div>
              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg">
                  <img
                    className
                    src="/img/loan/start-img3.jpg"
                    alt=""
                    title
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/start-img3.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  Can I Get A Startup Loan If I Have Bad Credit?
                </h3>
                <p style={{ color: "#FFF" }}>
                  Lenders focus on the personal credit history of the business
                  owner when deciding whether or not to award a startup loan.
                  However, lenders will also assess other aspects of your loan
                  request. Including a detailed business plan that outlines how
                  you plan to use the funding and an in-depth business forecast
                  that demonstrates your expected cash flow over the course of
                  the coming months and years will strengthen your case for a
                  loan. This makes startup loans an accessible financing option
                  for a variety of people, including those with bad credit.
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Questions?
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantadvance-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  What Are The Requirements For A Startup Loan?
                </h3>
                <p style={{ color: "#FFF" }}>
                  To be approved outright for a startup loan, you typically need
                  to have a credit score of 680 or higher and have been in
                  business for 6 months or more. Individuals who have experience
                  working in a field related to their startup have a higher
                  likelihood of securing a loan. Those with bad credit scores
                  may be required to offer up assets for collateral against
                  their loan, such as their car or house. Broco Financial Services can help you
                  find the best solution for your growing business. Contact us
                  today to get started.
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Questions?
                </a>
              </div>
              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg">
                  <img
                    className
                    src="/img/loan/start-img4.jpg"
                    alt=""
                    title
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="constantlygrowing-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                {/* <h2 /> */}
                <p style={{ color: "#FFF" }}>
                  Broco Financial Services is dedicated to helping passionate startup owners
                  secure the loans they need to grow and scale their businesses.
                  Contact us today to find the best loan option for your
                  business!
                </p>
                <a
                  className="btn btn-warning"
                  href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Startup;
