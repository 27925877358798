import React from "react";
import "./allLone.css";
function MerchantCashAdvance() {
  return (
    <div>
      <div className="ra-con">
        {/* <Background bgImage={bgImage} /> */}

        <div
          className="con-img">
          <div className="con-text">
            <div className="container">
              <div className="con-hed">
                <h1>
                  Merchant Cash
                  <br />
                  Advance
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingBottom: "40px",
          backgroundColor: "#0A0A0A",
          paddingTop: "3%",
        }}
      >
        <div className="constantlygrowing-section pt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <h2 />
                <p style={{ color: "#FFF" }}>
                  A merchant cash advance (MCA) is a great way to access the
                  quick capital you need to grow your business. Here at
                  Broco Financial Services, we specialize in helping businesses get the funding
                  they need to be successful, and an MCA is one of the tools you
                  have available! A merchant cash advance provides an
                  alternative to traditional small-business loans and is an
                  excellent option for startup businesses that have difficulty
                  obtaining other types of loans. Learn more about your options,
                  and if you're ready to get started, contact our team today!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/mca-img1.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  What Is A Merchant Cash Advance?
                </h3>
                <p style={{ color: "#FFF" }}>
                  A merchant cash advance (MCA) is a type of funding that allows
                  businesses to borrow money against their future sales. This
                  means that businesses can get the funding they need without
                  having to go through the traditional lending process. An MCA
                  allows you to leverage your future earnings to gain access to
                  fast capital. More so, because you pay back your loan through
                  a percentage of your daily sales, it'll stay off of your
                  credit report. This streamlined funding allows you to pay for
                  unexpected expenses as well as grow new businesses with
                  limited cash flow.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantadvance-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  How To Qualify For Merchant Cash Advance
                </h3>
                <p style={{ color: "#FFF" }}>
                  A merchant cash advance is relatively fast and easy, and the
                  requirements are more lenient than other types of loans.
                  You'll generally need to provide four to six months of past
                  bank statements and fill out a cash advance application. The
                  team at Broco Financial Services can help you through the entire process!
                </p>
              </div>
              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg pb-5">
                  <img
                    className
                    src="/img/loan/real-img2.jpg"
                    alt=""
                    title
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 pb-5">
              <div className="commercial-section">
                <h2 style={{ color: "#FF0202" }}>
                  {" "}
                  How Much Can You Borrow From A Merchant Cash Advance?
                </h2>
                <p>
                  The amount you can borrow from a merchant cash advance will
                  depend on your business's creditworthiness and sales volume.
                  However, most businesses can expect to receive between $5,000
                  and $250,000.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 pb-5">
              <div className="commercial-section">
                <h2 style={{ color: "#FF0202" }}>
                  {" "}
                  What Are The Terms of A Merchant Cash Advance?
                </h2>
                <p>
                  The terms of a merchant cash advance are typically much
                  shorter than those of a traditional loan. This means that
                  you'll need to repay the advance in a shorter amount of time.
                  The repayment schedule is also more flexible, and businesses
                  can typically choose to make payments weekly, bi-weekly, or
                  monthly.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*- After Box (section start)*/}
        <div className="qualifymerchant-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/Bloc-img1.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  Cash Advance For Startup Business
                </h3>
                <p style={{ color: "#FFF" }}>
                  A cash advance is a great option for startups that may not be
                  able to qualify for another business financing, and it is paid
                  back based on your daily sales, time in business, and other
                  factors. This means that if your startup hasn't started making
                  the revenue you're looking for, a cash advance can provide you
                  with the money you need to grow your business.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantadvance-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3 style={{ color: "#FF0202" }}>Survive Difficult Times</h3>
                <p style={{ color: "#FFF" }}>
                  An MCA can help your business survive difficult times. The
                  advance can help you pay your bills, keep your employees, and
                  cover other expenses while you wait for your business to start
                  making money. If you're ready to get started with a merchant
                  cash advance, contact our team! We'll help you through the
                  entire process and answer any questions you may have.{" "}
                </p>
              </div>
              <div className="col-lg-5 col-md-5 col-12 pb-5">
                <div className="cashadvanceimg ">
                  <img
                    className
                    src="/img/loan/mca-img2.jpg"
                    alt=""
                    title
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/real-img3.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  How Merchant Cash Advance Is Repaid
                </h3>
                <p style={{ color: "#FFF" }}>
                  MCA is repaid through a percentage of your daily sales or a
                  percentage of credit card sales. This means that if you have a
                  slow time, you'll still be able to make your payment without
                  putting a strain on your business. MCA is a great option for
                  businesses that have irregular sales or are looking for a more
                  flexible repayment schedule. While terms vary by lender, the
                  team at Broco Financial Services can help walk you through the process and
                  help you get the best possible financing deal for your
                  business.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*- After Box (section end)*/}
        <div className="constantlygrowing-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <h2 />
                <p style={{ color: "#FFF" }}>
                  If you're looking for a fast and easy way to get the funding
                  your business needs, a merchant cash advance may be the right
                  option for you! Broco Financial Services can help you qualify for an MCA and
                  has the experience to make the process as smooth as possible.
                  Give us a call today!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantCashAdvance;
