

// const value= () => {[
  
   
//   ];
// }

// export default value;

const defaultMarkers = [
  {
    id: 1,
    city: 'Singapore',
    color: 'red',
    coordinates: [1.3521, 103.8198],
    value: 50,
  },
  {
    id: 2,
    city: 'New York',
    color: 'blue',
    coordinates: [40.73061, -73.935242],
    value: 25,
  },
  {
    id: 3,
    city: 'San Francisco',
    color: 'orange',
    coordinates: [37.773972, -122.431297],
    value: 35,
  },
  {
    id: 4,
    city: 'Beijing',
    color: 'gold',
    coordinates: [39.9042, 116.4074],
    value: 0,
  },
  {
    id: 5,
    city: 'London',
    color: 'green',
    coordinates: [51.5074, 0.1278],
    value: 80,
  },
];

export default defaultMarkers;