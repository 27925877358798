import { useState, useEffect } from "react";
import "./about.css";
 



  const About = () => {


  const [aboutBanner, setAboutBanner] = useState([]);


  useEffect(() => {
    fetch("https://brocofinancial.com/backend/public/api/aboutBanner")
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            console.log(data);
            if (data.status) {
              setAboutBanner(data);
            } else {
                console.log(
                    "No data or empty array received from the API."
                );
            }
        })
        .catch((error) => {
            console.log(error);
        });
  }, []);
  
  
  const{data,path} = aboutBanner;
  let data2 = data?data.aboutbanner:"";
  console.log(data);










  return (  
    <div>
      <div className="ra-blog-banner">
        {/* <Background bgImage={bgImage} /> */}
        <div className="">
        <img src={`${path}/${data2}`} alt=""/>
        </div>
        
          <div className="blog-text">
            <div className="container">
              <div className="blog-hed">
                <h1>
                  About
                  <br />
                  Us
                </h1>
              </div>
            </div>
          </div>
     
      </div>
      <div className="about">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-hed">
              <h1>
                <span>The Story</span> <br />
                Behind Us
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id
                elit nec quam vestibulum malesuada. Cras facilisis congue elit.
                Interdum et malesuada fames ac ante ipsum primis in faucibus.
                Pellentesque pharetra id orci blandit venenatis. Sed vulputate
                sapien id nisl pharetra maximus. Donec sed erat quis diam
                euismod commodo. Phasellus vel lorem dictum, tempus purus id,
                imperdiet ex. Cras eget ligula lacinia, varius eros vel,
                consectetur ligula. Curabitur laoreet quam eu semper consequat.
                Nunc urna augue, commodo ut gravida vel, ullamcorper a metus.
                Orci varius natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Nulla malesuada sollicitudin luctus.
                Mauris dignissim arcu vel risus ornare, id varius lacus
                pharetra. Donec pulvinar lectus est, eu pretium augue finibus
                sit amet. Nunc sed erat interdum, eleifend elit in, elementum
                nulla. Nam convallis ex ut quam vulputate congue. Sed at gravida
                felis. Nullam a tempor est. Praesent molestie, ligula eu aliquam
                maximus, orci dolor rutrum metus, at maximus mauris ante mollis
                mi.
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="rj">
              <img src="img/aboutPhoto.jpg" alt="" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="ra-about-story">

          <p>
            Ut pulvinar velit lectus, sit amet fermentum elit facilisis sit
            amet. Nunc porttitor lorem vitae tincidunt faucibus. Pellentesque
            vel orci sed erat fermentum commodo. Nam eget scelerisque risus.
            Integer efficitur, odio ac porttitor vestibulum, felis risus dictum
            nibh, nec blandit nisl mauris in risus. Proin sed risus eget leo
            bibendum blandit non pharetra sem. Donec eget nibh quam. Aenean
            accumsan pharetra pulvinar. Nam vitae nulla magna.
          </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
