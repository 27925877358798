import { useState, useEffect } from "react";
import "./Home.css";

import Button from "react-bootstrap/Button";

function Home() {




  const [loan, setLoan] = useState([]);
  const [vedio, setVedio] = useState([]);



  useEffect(() => {
    fetch("https://brocofinancial.com/backend/public/api/homeService")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          setVedio(data);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);







  useEffect(() => {
    fetch("https://brocofinancial.com/backend/public/api/homeService")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          setLoan(data);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


    const{data,path} = vedio;
    // let data1 = data?data.bannervideo:"";
    let loan2 = loan.data? loan.data:[];
  console.log(data);




  // const{data,path} = vedio;
  // let data1 = data?data.bannervideo:"";
  // console.log(data);

  return (
    <>
      <div className="ra-home">
        <video loop autoPlay muted id="bg-video">
          <source
            src={require("../assets/Backgroundvedio.mp4")}
            type="video/mp4"
          />
        </video>

        <div className="ra-text">
          <div className="container">
            <div className="ra-hede">
              <h1>
                Financing <br />
                Your Future
              </h1>
                < p> 
                Providing comprehensive loan services to help you reach your
                goals. Business, home, personal We’re here for you.
              </p>

              <div
                style={{
                  color: "#fff",
                  paddingTop: "10px",
                }}
              >
                <Button
                  style={{
                    borderRadius: "0px",
                    border: "2px solid #fff",
                  }}
                  variant=""
                  size="m"
                  active
                >
                  Get Started Today
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>


     {/* //.................// */}

      <div className="mainlone">
        <div className="container">
        

                
          <div className="ra-lone">
            <div>
              <h2 className="ra-lone-hed">
                Service We offer
              </h2>
            </div>
          </div>
          <div className="row">
          {loan2.map((d2,index) => (
              <div key={index} className="col-md-4" >
                {/* <div>{data.id}</div> */}
            
              <div className="card">
                <div>
                <img src={`${loan.path}/${d2.image}`}
                  alt=""
                  style={{ width: "100%" }}
                />
                </div>
                <div className="ra-card1">
                  <div className="container">
                    <p
                      className="title"
                      style={{
                        paddingTop: "10px",
                        fontSize: "20px",
                        fontWeight: "700",
                        margin: "0",
                        color: "#FF0202",
                      }}
                    >
                      {d2.bussinessname}
                    </p>
                    <p
                      style={{
                        fontSize: "16px",

                        lineHeight: "1.3rem",
                        paddingBottom: "16px",
                        color: "#fff",
                        paddingRight: "10.5",
                      }}
                      st
                    >
                     {d2.description}
                    </p>
                  </div>
                </div>
              </div>
            

            </div>
        ))}
            
          </div>
        </div>
      </div>

      {/* //..............// */}


      <div className="ra-about">
        <div className="row">
          <div className="col-md-7">
            <div className="am-aboutText">
              <span>About</span>
              <h1> Broco Solution Centre </h1>
              <h2>An Affiliate of Broco International Trust</h2>
              <p>
                Aenean sollicitudin ipsum eget urna varius, sed consectetur
                augue molestie. Sed nibh purus, venenatis at pretium eu, mattis
                non lorem. Duis at libero eget mauris tristique varius ut a est.
                Pellentesque in nisl faucibus, gravida ex a, rhoncus elit. Sed
                volutpat diam tellus, at molestie urna sodales in.
              </p>
            </div>
            <div className="ra-about-btn" >
              <Button
                style={{
                  borderRadius: "0px",
                  border: "2px solid #fff",
                }}
                variant=""
                size="m"
                active
              >
                Read more
              </Button>
            </div>

            
          </div>

          <div className="col-md-5">
            <div className="am-aboutimg">
              <img src="img/AboutImg.jpg" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          className="contact-img"
          style={{ backgroundImage: "url('img/PHOTO-POSTER.jpg')" }}
        >
          <div className="container">
            <div className="ra-title-c">
              <h1>
                We Take The Stress <br />
                Out Of
                <span> Securing Funding</span>
              </h1>
              <p>
                In need of funding, but not sure where to start? At Broco
                Financial, our goal is to offer financial solutions that
                <br /> are quick, convenient, and easy to understand. Contact us
                today or fill out our application to get started.
              </p>
            </div>
            <div className="btn-c3" >
              <Button variant="" className="btn-c3-ra" size="lg" active>
                Read more
              </Button>
            </div>
            <div className="ra-part2">
              <div
                className="ra-con-title"
                style={{
                  paddingTop: "30px",
                  paddingBottom: "30px",
                }}
              >
                <div className="row">
                  <div className="col-sm-5">
                    <h1>How it Works</h1>
                  </div>
                  <div className="col-sm-7">
                    <p>
                      Donec condimentum est a erat auctor, vel efficitur risus
                      pulvinar. Quisque porta dui quis velit dignissim elementum
                      id et erat. Morbi consequat tellus non ultricies maximus.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ra-con-box">
                <ul className="ra-box1">
                  <div className="row">
                    <div className="col">
                      <card>
                        <li className="tm-ra-con-box-panel">
                          <div className="content">
                            <strong className="tm-ra-con-box-title">
                              1.
                              <span>
                                {" "}
                                Online <br />
                                Application
                              </span>
                            </strong>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.
                            </p>
                          </div>
                        </li>
                      </card>
                    </div>
                    <div className="col">
                      <card>
                        <li className="tm-ra-con-box-panel">
                          <div className="content">
                            <strong className="tm-ra-con-box-title">
                              2.
                              <span>
                                {" "}
                                Choose <br />
                                Offer
                              </span>
                            </strong>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.
                            </p>
                          </div>
                        </li>
                      </card>
                    </div>
                    <div className="col">
                      <card>
                        <li className="tm-ra-con-box-panel">
                          <div className="content">
                            <strong className="tm-ra-con-box-title">
                              3.
                              <span>
                                {" "}
                                Met Your <br />
                                Advisor{" "}
                              </span>
                            </strong>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.
                            </p>
                          </div>
                        </li>
                      </card>
                    </div>
                    <div className="col">
                      <card>
                        <li className="tm-ra-con-box-panel">
                          <div className="content">
                            <strong className="tm-ra-con-box-title">
                              4.
                              <span>
                                Met Your
                                <br />
                                Advisor{" "}
                              </span>
                            </strong>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.
                            </p>
                          </div>
                        </li>
                      </card>
                    </div>
                    <div className="col">
                      <card>
                        <li className="tm-ra-con-box-panel1">
                          <div className="content">
                            <strong className="tm-ra-con-box-title">
                              5.
                              <span>
                                {" "}
                                Online <br />
                                Application
                              </span>
                            </strong>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.
                            </p>
                          </div>
                        </li>
                      </card>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ra-whyChoose">
        <div className="row">
          <div className="col-md-6">
            <div className="ra-contact1-img">
              <img src="img/photo1.jpg" alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="container">
              <div
                className="ra-whyChoose-title">
                <span>
                  Why Choose{" "}
                  <h3>
                    Us <span>?</span>
                  </h3>
                  
                </span>

                <p>
                  Nullam rhoncus aliquet elit rhoncus faucibus. Sed sed
                  convallis lacus. Sed blandit risus velit, interdum volutpat
                  diam rutrum non. Nulla fringilla, lectus sed fringilla
                  accumsan,
                </p>
                <div className="ra-box">
                  <p>
                    <i class="fa-solid fa-check"></i>
                    Nulla consectetur neque urna, eget congue
                  </p>
                  <p>
                    <i class="fa-solid fa-check"></i>
                    Nulla mattis tincidunt neque eu lobortis.
                  </p>
                  <p>
                    <i class="fa-solid fa-check"></i>
                    Proin non porta ex. Pellentesque vel metus volutpat,
                  </p>
                  <p>
                    <i class="fa-solid fa-check"></i>
                    Vestibulum volutpat lectus sit amet metus blandit vehicula.
                  </p>
                  <p>
                    <i class="fa-solid fa-check"></i>
                    Vestibulum in erat sollicitudin, tincidunt velit at
                  </p>
                </div>
              </div>
              <div className="ra-whyChoose-btn" >
                <Button variant="" className="btn-c1" size="lg" active>
                  Read more
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
