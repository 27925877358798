import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Link } from "react-router-dom";
import "./Home.css";
import { useState } from "react";
import { NavLink } from "react-router-dom";

function Navber() {
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = () => {
    setExpanded(false);
  };
  // const [menu, setMenu] = useState("");
  return (
    // <div className="mainnav">

    <Navbar
      expanded={expanded}
      expand="md"
      // variant="dark"
      fixed="top"
      onToggle={(expanded) => setExpanded(expanded)}
      className="mainnav"
    >
      <Container fluid>
        <div className="tm-mainnav-panel">
          <Navbar.Brand as={NavLink} to="/">
            <div className="ra-hed">              
              <div className="ra-hed-panel">
                <div className="ra-nab-hed">
                  <h6>Broco Financial Services</h6>
                </div>
              </div>
              <div className="ra-hed-panel">
                <div className="ra-nab-img">
                  <img src="img/navber-logo1.png" alt="" />
                </div>
              </div>             
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="ra-dpd">
            <Nav
              className="me-auto my-2 my-lg-0"
              // style={{ maxHeight: "100px", paddingLeft: "21%" }}
              navbarScroll
              href="/home"
            >
              <NavDropdown title="Loans & Mortgages">
                {["end"].map((direction) => (
                  <NavDropdown
                    key={direction}
                    id="subNavDropdown"
                    drop={direction}
                    title={`Business Loans`}
                    className="nav-dropdown"
                  >
                    <NavDropdown.Item
                      as={NavLink}
                      to="/RealEstateLoans"
                      onClick={handleLinkClick}
                    >
                      Real Estate Loans
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/Construction"
                      onClick={handleLinkClick}
                    >
                      R.E. Rehab / Construction
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/EquipmentFinancing"
                      onClick={handleLinkClick}
                    >
                      Equipment Financing
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/Startup"
                      onClick={handleLinkClick}
                    >
                      Startup Loan
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/USDA"
                      onClick={handleLinkClick}
                    >
                      USDA Loans
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/SBA"
                      onClick={handleLinkClick}
                    >
                      SBA Loans
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/ShortTerm"
                      onClick={handleLinkClick}
                    >
                      Short Term Loans
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/BusinessLineOfCredit"
                      onClick={handleLinkClick}
                    >
                      Business Line Of Credit
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/BusinessAcquistionFranchise"
                      onClick={handleLinkClick}
                    >
                      Business Acquistion & Franchise
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/Financing"
                      onClick={handleLinkClick}
                    >
                      Financing
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/MerchantCashAdvance"
                      onClick={handleLinkClick}
                    >
                      Merchant Cash Advance
                    </NavDropdown.Item>
                  </NavDropdown>
                ))}
                {["end"].map((direction) => (
                  <NavDropdown
                    key={direction}
                    id="subNavDropdown"
                    drop={direction}
                    title={`Home Loans`}
                    className="nav-dropdown"
                  >
                    <NavDropdown.Item
                      as={NavLink}
                      to="/BuildingaHome"
                      onClick={handleLinkClick}
                    >
                      Building a Home
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/PurchaseaHome"
                      onClick={handleLinkClick}
                    >
                      Purchase a Home
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/PurchaseLand"
                      onClick={handleLinkClick}
                    >
                      Purchase Land
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/RefinanceaHome"
                      onClick={handleLinkClick}
                    >
                      Refinance a Home
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/Reverseamortgage"
                      onClick={handleLinkClick}
                    >
                      Reverse a mortgage
                    </NavDropdown.Item>
                  </NavDropdown>
                ))}
                {["end"].map((direction) => (
                  <NavDropdown
                    key={direction}
                    id="subNavDropdown"
                    drop={direction}
                    title={`Personal Loans`}
                    className="nav-dropdown"
                  >
                    <NavDropdown.Item
                      as={NavLink}
                      to="/PersonalCreditCard"
                      onClick={handleLinkClick}
                    >
                      Personal Credit Card
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/PersonalLineofCredit"
                      onClick={handleLinkClick}
                    >
                      Personal Line of Credit
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/PersonalTermLoan"
                      onClick={handleLinkClick}
                    >
                      Personal Term Loans
                    </NavDropdown.Item>
                  </NavDropdown>
                ))}
              </NavDropdown>

              {["down"].map((direction) => (
                  <NavDropdown
                    key={direction}
                    id="subNavDropdown"
                    drop={direction}
                    title={`Solution Center`}
                    className="nav-dropdown"
                  >
                    <NavDropdown.Item
                      as={NavLink}
                      to=""
                      onClick={handleLinkClick}
                    >
                     Employees Sign In
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={NavLink}
                      to="/PartnerSign"
                      onClick={handleLinkClick}
                    >
                      
                      Partner Sign In
                    </NavDropdown.Item>
                    
                  </NavDropdown>
                ))}

              

              <Nav.Link
                as={NavLink}
                to="/blog"
                style={{ color: "#fff" }}
                onClick={handleLinkClick}
              >
                Blog
              </Nav.Link>

              <Nav.Link
                as={NavLink}
                to="/about"
                style={{ color: "#fff" }}
                onClick={handleLinkClick}
              >
                About us
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/contact"
                style={{ color: "#fff" }}
                onClick={handleLinkClick}
              >
                Contact us
              </Nav.Link>
            </Nav>
            <Form className="ra-form" >
              <div
                style={{
                  color: "#fff",
                }}
              >
                <Button variant="" className="btn-c" size="sm" active>
                  Sign In
                </Button>
              </div>
            </Form>
          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar>
    // </div>
  );
}

export default Navber;
