import React from 'react'

function Reverseamortgage() {
  return (
    <div>
    <div className="ra-con">
      {/* <Background bgImage={bgImage} /> */}

      <div
        className="con-img">
        <div className="con-text">
          <div className="container">
            <div className="con-hed">
              <h1>
                Business Acquistion
                <br />
                Franchise
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style={{
        paddingBottom: "40px",
        backgroundColor: "#0A0A0A",
        paddingTop: "5%",
      }}
    >
        <section className="area-padding">
          <div className="container px-4 text-center">
            <div className="row gx-5">
              <div className="col-lg-6 service-page-post m-auto">
                <div className="service-page-content ">
                  <p>A reverse mortgage is a loan, in the sense that it allows an eligible homeowner to borrow money but it doesn’t work the same way as a home purchase loan. A homeowner who is 62 or older and has considerable home equity can borrow against the value of their home and receive funds as a lump sum, fixed monthly payment, or line of credit. Unlike a forward mortgage—meaning the type used to buy a home—a reverse mortgage doesn’t require the homeowner to make any loan payments during their lifetime.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tm-about-us-img">
                  <div className="tm-about-us-img-bg">
                    <img className="img-fluid" src="https://demo.webaiders.com/cheddarr/wp-content/themes/cheddarr/img/about-img-bg.png" alt="" /> 
                  </div>
                  <div className="about-page-image">
                    <img src="./img/loan/securing-funding-1.png"  className="img-fluid" alt="image" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-5 pt-lg-4">
              <div className="col-12">
                <div className="service-page-content">
                  <p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Get-Start-section area-padding text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="Get-section">
                  <div className="tm-sec-title">
                    <h3 >Get Started Now on <br /><span>Your Financial Goals</span></h3>
                  </div>
                  <div className="get-content text-white">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget arcu arcu. Morbi vitae elit rutrum nulla interdum feugiat. 
                      Proin facilisis nulla ut turpis volutpat aliquet.
                      Praesent eget leo sit amet ante varius euismod.</p>
                  </div>
                  <div className="d-flex tm-header-btn justify-content-center">
                    <a href="/contact"><button className="btn tm-header-btn1" type="submit">
                        <span>Apply For Home Purchase</span>
                      </button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Reverseamortgage
