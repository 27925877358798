import React from "react";
import "./allLone.css";

function BusinessLineOfCredit() {
  return (
    <div>
      <div className="ra-con">
        {/* <Background bgImage={bgImage} /> */}

        <div
          className="con-img">
          <div className="con-text">
            <div className="container">
              <div className="con-hed">
                <h1>
                  Business Line
                  <br />
                  Of Credit
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#0A0A0A" }}>
        <div className="constantlygrowing-section pt-5 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <h2 />
                <p style={{ color: "#fff" }}>
                  Are you looking for flexible financing options for your
                  business? A business line of credit might be exactly what
                  you’re looking for. These flexible financing solutions can be
                  used to cover almost every need you might have as a small
                  business owner, and their unique interest structure means you
                  only pay interest on the funds you use. Broco Financial Services specializes
                  in helping small business owners secure the loans they need to
                  empower their business. Contact us today to get started!
                </p>
                <a
                  className="btn btn-warning"
                  href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/Bloc-img1.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  What Is A Business Line Of Credit?
                </h3>
                <p style={{ color: "#fff" }}>
                  Think of a business line of credit as financial insurance you
                  can use to cover almost any business expense when it arises.
                  It’s like a financial safety net for your business. Once you
                  qualify for a business line of credit you can use it whenever
                  you need to, but you aren’t obligated to use it unless you
                  have to. The best part? You only pay interest on the amount
                  that you use, not the full amount of your business line of
                  credit. Once you’ve used your credit and repaid it, the full
                  amount of funds is available to you again. It’s a win-win for
                  small business owners.
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Questions?
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantadvance-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  Can You Get A Business Line Of Credit For A New Business?
                </h3>
                <p style={{ color: "#fff" }}>
                  Broco Financial Services can help you secure a business line of credit for
                  your new business! Businesses typically need to be in business
                  for 6 months or more to qualify, but lenders look at a variety
                  of factors when deciding whether or not to issue a business
                  line of credit. Broco Financial Services has access to a wide range of
                  reputable lenders so it never hurts to give us a call. Contact
                  us today to learn more!
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Questions?
                </a>
              </div>
              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg">
                  <img
                    className
                    src="/img/loan/Bloc-img2.jpg"
                    alt=""
                    title
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/Bloc-img3.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  What Do You Need To Qualify For A Business Line Of Credit?
                </h3>
                <p style={{ color: "#fff" }}>
                  As mentioned above, time in business is only one of the
                  factors that will determine whether or not you qualify for a
                  business line of credit. Lenders will typically want to see
                  $50,000 or more in annual revenue to qualify for a business
                  line of credit, and you’ll need to have a credit score of 560
                  or higher. It’s not uncommon for business owners to be
                  required to offer collateral to secure the line of credit. You
                  may be asked to levy personal assets like your car, bank
                  account, or house to get the deal done.
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Questions?
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantadvance-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  How Does Interest Work On A Business Line Of Credit?
                </h3>
                <p style={{ color: "#fff" }}>
                  Since a business line of credit is designed to be a financial
                  safety net for your business, you aren’t obligated to use the
                  full amount within your loan term, and you are only required
                  to pay interest on the amount you use. For example, if you are
                  approved for a $50,000 business line of credit and you pull
                  $30,000 of it to make a few crucial hires and renovate your
                  office, you are only required to pay interest on the $30,000.
                  This is what makes business line of credit one of the more
                  attractive and flexible financing options available to small
                  business owners.
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Questions?
                </a>
              </div>
              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg">
                  <img
                    className
                    src="/img/loan/Bloc-img4.jpg"
                    alt=""
                    title
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="constantlygrowing-section pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <h2 />
                <p style={{ color: "#fff" }}>
                  {" "}
                  Are you interested in securing a business line of credit?
                  Broco Financial Services is passionate about helping small business owners
                  secure the financing they need to grow their enterprise and
                  conquer all of the challenges they face along the way. Get in
                  touch with us today to get started!
                </p>
                <a
                  className="btn btn-warning"
                  href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessLineOfCredit;
