import React from "react";
import "./allLone.css";

function ShortTerm() {
  return (
    <div>
      <div className="ra-con">
        {/* <Background bgImage={bgImage} /> */}

        <div
          className="con-img">
          <div className="con-text">
            <div className="container">
              <div className="con-hed">
                <h1>
                  Short Term
                  <br />
                  Loans
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingBottom: "40px",
          backgroundColor: "#0A0A0A",
          paddingTop: "8%",
        }}
      >
        <div className="constantlygrowing-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <h2 />
                <p style={{ color: "#FFF" }}>
                  At Broco Financial Services, we’re committed to helping small businesses find
                  the loans, funding, and financing options that they need to
                  succeed. Learn more about our other services and loan options
                  by browsing our service page or reaching out to us today. Get
                  started with a quote from our team today!{" "}
                </p>
                <a
                  className="btn btn-warning"
                  href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/con-img1.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  Short Term Notice, Short Term Payments
                </h3>
                <p style={{ color: "#FFF" }}>
                  What exactly are short-term loans? It’s all in the name:
                  they’re loans that you can obtain on short notice and spend a
                  shorter amount of time paying back than you would with other
                  types of loans. Because these loans are designed specifically
                  for small businesses like yours, they’re flexible and offer
                  short-term options for disbursements and repayment terms.
                  Short-term business loans have predictable repayment terms and
                  no long-term obligations and so there are no surprises down
                  the road. Irrespective of the industry, any small business
                  needs temporary cash for a particular business purpose can
                  benefit from a short-term business loan. Cheddar offers
                  customized, short-term financing for businesses in a wide
                  range of industry sectors.
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantadvance-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  Flexible Funding with Short Term Loans for Businesses{" "}
                </h3>
                <p style={{ color: "#FFF" }}>
                  One of the most attractive aspects of short-term loans for
                  small businesses is that they are flexible funds that can help
                  fulfill all kinds of small business needs. A short-term loan
                  for bad credit score is a convenient option for immediate
                  money. We understand that financial emergencies do arise when
                  you least expect it. From using these loans to cover
                  unexpected costs, surviving a slump in revenue (like you might
                  see during a lockdown or recession), or even to finance short
                  term projects that will improve your business in the long run,
                  you can use these flexible funds to help your business succeed
                  and grow. Finding the best loan option for you is our
                  specialty! Instant funding is available.{" "}
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg">
                  <img
                    className
                    src="/img/loan/sTL-img2.jpg"
                    alt=""
                    title
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="qualifymerchant-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-4 col-12">
                <div className="qualifymerchant-img">
                  <img
                    src="/img/loan/real-img3.jpg"
                    className
                    alt=""
                    decoding="async"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  Short Term Business Loans with Low Interest Rates Saves More{" "}
                </h3>
                <p style={{ color: "#FFF" }}>
                  If you’re trying to obtain a short-term loan, you might be
                  stressing out about qualifying or requirements for getting
                  awarded the amount you need. However, you don’t need to
                  stress; these loans are easier to qualify for than you’d
                  think. If you’ve been in business for two years or more, you
                  have a good chance of qualifying, and with the help of your
                  team at Broco Financial Services, we can find the right lender with terms and
                  low-interest rates that will help you to spend less out of
                  pocket on interest and more on your business for long-term
                  success.{" "}
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="merchantadvance-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                <h3 style={{ color: "#FF0202" }}>
                  Short Term Business Loans for Bad Credit{" "}
                </h3>
                <p style={{ color: "#FFF" }}>
                  Short-term loans are a powerful tool for small business owners
                  to use in the event of a surprise. If you’re struggling with
                  covering the costs of repairing damage to your building from
                  inclement weather, or if a large, essential appliance broke
                  down unexpectedly, working with the loan experts at Broco Financial Services
                  can help to get you back on track! A short term loan on bad
                  credit takes into account even the less than perfect credit
                  history. You might be surprised to discover that these loans
                  can provide you with cash in just a few days, allow you to use
                  the money however you choose, and also give you adequate time
                  to pay back the loan. We’ll work with you to find you the best
                  deal for your situation so your business can thrive!{" "}
                </p>
                <a
                  className="btn btn-warning"
                  href="/contact"
                  target="_self"
                >
                  Enquire Now
                </a>
              </div>
              <div className="col-lg-5 col-md-5 col-12">
                <div className="cashadvanceimg">
                  <img
                    className
                    src="/img/loan/real-img2.jpg"
                    alt=""
                    title
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="constantlygrowing-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <h2 />
                <p style={{ color: "#FFF" }}>
                  At Broco Financial Services, we’re committed to helping small businesses find
                  the loans, funding, and financing options that they need to
                  succeed. Learn more about our other services and loan options
                  by browsing our service page or reaching out to us today. Get
                  started with a quote from our team today!.{" "}
                </p>
                <a
                  className="btn btn-warning"
                  href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShortTerm;
