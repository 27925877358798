import React from "react";
import "./allLone.css";

function BusinessAcquistionFranchise() {
  return (
    <div>
      <div className="ra-con">
        {/* <Background bgImage={bgImage} /> */}

        <div
          className="con-img">
          <div className="con-text">
            <div className="container">
              <div className="con-hed">
                <h1>
                  Business Acquistion
                  <br />
                  Franchise
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingBottom: "40px",
          backgroundColor: "#0A0A0A",
          paddingTop: "8%",
        }}
      >
        <div className="constantlygrowing-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <h2 style={{ color: "#FF0202" }}>
                  Explore Our Business Acquistion Information
                </h2>
                <p> </p>
                <a
                  className="btn btn-warning"
                  href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="constantlygrowing-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 pt-5">
                <h2 style={{ color: "#FF0202" }}>
                  Explore Our Franchise Financing Information
                </h2>
                <p> </p>
                <a
                  className="btn btn-warning"
                  href="https://app.lendingwise.com/HMLOWebForm.php?view=c5e7c430d26333b5&bRc=91d31e8a24296cbe&aRc=a9056fd458f47dc8&fOpt=c1cca7825dbc8710&op=aa4465703ef4b17e&ft=loc"
                  target="_self"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessAcquistionFranchise;
