import React from 'react'

function RefinanceaHome() {
  return (
    <div>
    <div className="ra-con">
      {/* <Background bgImage={bgImage} /> */}

      <div
        className="con-img">
        <div className="con-text">
          <div className="container">
            <div className="con-hed">
              <h1>
                Business Acquistion
                <br />
                Franchise
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style={{
        paddingBottom: "40px",
        backgroundColor: "#0A0A0A",
        paddingTop: "5%",
      }}
    >
        <section className="area-padding">
          <div className="container px-4 text-center">
            <div className="row gx-5">
              <div className="col-lg-6 service-page-post m-auto">
                <div className="service-page-content ">
                  <h2 className="x_elementToProof x_ContentPasted6">4 Reasons To Refinance Your Mortgage</h2>
                  <div className="x_elementToProof x_ContentPasted6"><br aria-hidden="true" />&nbsp;<p />
                    <div className="x_ContentPasted6" style={{color:"#fff"}}>As we mentioned, there are a variety of reasons why you might want to refinance your existing mortgage. Let’s look at some of the main reasons here.</div>
                    <div />
                    <div className="x_ContentPasted6" style={{color:"#fff"}}>1. Change Your Loan Term</div>
                    <div className="x_ContentPasted6" style={{color:"#fff"}}>Many people refinance to a shorter term to save on interest. For example, say you started with a 30-year loan but can now afford a higher mortgage payment. You might refinance to a 15-year term to get a better interest rate and pay less interest overall.</div>
                    <div />
                    <div className="x_ContentPasted6" style={{color:"#fff"}}>You can also refinance to a longer term to lower your monthly payment.</div>
                    <div />
                    <div className="x_ContentPasted6" style={{color:"#fff"}}>2. Lower Your Interest Rate</div>
                    <div className="x_ContentPasted6" style={{color:"#fff"}}>Interest rates are always changing. If rates are better now than when you got your loan, refinancing might make sense for you. Lowering your interest rate can lower your monthly payment. You’ll likely pay less total interest over the life of your loan as well.</div>
                    <div />
                    <div className="x_ContentPasted6" style={{color:"#fff"}}>3. Change Your Loan Type</div>
                    <div className="x_ContentPasted6" style={{color:"#fff"}}>A different type of loan or loan program may benefit you for a number of reasons. Perhaps you originally got an adjustable-rate mortgage (ARM) to save on interest, but you’d like to refinance your ARM to a fixed-rate mortgage while rates are low.</div>
                    <div />
                    <div className="x_ContentPasted6" style={{color:"#fff"}}>Maybe you finally have enough home equity to refinance your Federal Housing Administration (FHA) loan to a conventional loan and stop paying a mortgage insurance premium (MIP).</div>
                    <div />
                    <div className="x_ContentPasted6" style={{color:"#fff"}}>4. Cash Out Your Equity</div>
                    <div className="x_ContentPasted6" style={{color:"#fff"}}>With a cash-out refinance, you borrow more than you owe on your home and pocket the difference as cash. If your home’s value has increased, you may have enough equity to take cash out for home improvement, debt consolidation or other expenses.</div>
                    <div />
                    <p>Using cash from your home allows you to borrow money at a much lower interest rate than other loan types. A cash-out refinance can have tax implications, though.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="tm-about-us-img">
                  <div className="tm-about-us-img-bg">
                    <img className="img-fluid" src="https://demo.webaiders.com/cheddarr/wp-content/themes/cheddarr/img/about-img-bg.png" alt="" /> 
                  </div>
                  <div className="about-page-image">
                    <img src="./img/loan/securing-funding-1.png"  className="img-fluid" alt="image" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-5 pt-lg-4">
              <div className="col-12">
                <div className="service-page-content">
                  <p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Get-Start-section area-padding text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="Get-section">
                  <div className="tm-sec-title">
                    <h3 >Get Started Now on <br /><span>Your Financial Goals</span></h3>
                  </div>
                  <div className="get-content text-white">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget arcu arcu. Morbi vitae elit rutrum nulla interdum feugiat. 
                      Proin facilisis nulla ut turpis volutpat aliquet.
                      Praesent eget leo sit amet ante varius euismod.</p>
                  </div>
                  <div className="d-flex tm-header-btn justify-content-center">
                    <a href="/contact"><button className="btn tm-header-btn1" type="submit">
                        <span>Apply For Home Purchase</span>
                      </button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default RefinanceaHome
