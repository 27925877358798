import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// import { useHistory } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';


const PartnerLogin = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });


  // const history = useHistory();
  const navigate = useNavigate();


  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify(formData);
      const res = await axios.post(
        "https://brocofinancial.com/backend/public/api/login",
        body,
        config
      );
      navigate('/');
      console.log(res.data);
      // history.push("/");
    } catch (err) {
      console.error(err.response.data);
    }
  };

  return (
    <Fragment>
       <div className="card1">
      <h1 className="ra-login">Sign In</h1>
     
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            onChange={onChange}
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            required
          />
        </div>
        <div className="form-group">
          <input
            onChange={onChange}
            type="password"
            placeholder="Password"
            name="password"
            minLength="6"
            value={password}
            required
          />
        </div>
        <input type="submit" className="btn btn-primary" value="Login" onClick={onSubmit} />
      </form>
      <p className="my-1">
        Don't have an account? <Link to="/PartnerSign" style={{
            textDecoration:"none",
        }}>Sign Up</Link>
      </p>

      </div>
    </Fragment>
  );
};

export default PartnerLogin;