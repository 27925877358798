import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import ReactGlobe from "react-globe";
import ReactDOM from "react-dom";
// import * as THREE from 'three';
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "./contact.css";
import defaultMarkers from "./markers";

function markerTooltipRenderer(marker) {
  return `CITY: ${marker.city} (Value: ${marker.value})`;
}

const options = {
  markerTooltipRenderer,
};

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [contactBanner, setContactBanner] = useState({});

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const [markers, setMarkers] = useState([]);
  const [event, setEvent] = useState(null);
  const [details, setDetails] = useState(null);

  const onClickMarker = (marker, event) => {
    // Handle marker click event
    console.log(`Marker clicked: ${marker.city}`);
    setDetails(`City: ${marker.city}, Value: ${marker.value}`);
    setEvent(event);
  };

  const onDefocus = () => {
    // Handle defocus event
    setDetails(null);
    setEvent(null);
  };

  useEffect(() => {
    fetch("https://brocofinancial.com/backend/public/api/contactBanner")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          setContactBanner(data);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const { data, path } = contactBanner;
  let data3 = data ? data.bannerimage : "";
  console.log(data);

  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [phoneNo, setPhoneNo] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate user input
    if (!firstName || !lastName || !phoneNo || !email || !message) {
      setStatusMessage("Please fill in all fields.");
      return;
    }

    // Send form data to email
    const templateParams = {
      from_name: `${firstName} ${lastName}`,
      from_email: email,
      phone_no: phoneNo,
      message: message,
    };

    emailjs.send('')
      .then((response) => {
          console.log('Email sent successfully!');
          setStatusMessage("Your message has been sent successfully!");
        })
      .catch((error) => {
          console.error('Error sending email:', error);
          setStatusMessage("Error sending email. Please try again.");
        });
  };

  const randomMarkers = defaultMarkers.map((marker) => ({
    ...marker,
    value: Math.floor(Math.random() * 100),
  }));
  // const [markers, setMarkers] = useState([]);
  // const [event, setEvent] = useState(null);
  // const [details, setDetails] = useState(null);

  return (
    <div>
      <div className="ra-con">
        <div className="">
          <img src={`${path}/${data3}`} alt="" />
        </div>
        <div className="con-text">
          <div className="container">
            <div className="con-hed">
              <h1>
                Contact
                <br />
                Us
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div>
        {details && (
          <div
            style={{
              background: "white",
              position: "absolute",
              fontSize: 20,
              bottom: -190,
              right: 28,
              padding: 12,
            }}
          >
            <p>{details}</p>
            <p>
              EVENT: type={event.type}, position=
              {JSON.stringify(event.pointerEventPosition)})</p>
          </div>
        )}
        <div style={{ padding: "32px", position: "absolute" }}>
          <button onClick={() => setMarkers(randomMarkers)}>
            Randomize markers
          </button>
          <button
            disabled={markers.length === 0}
            onClick={() => setMarkers([])}
          >
            Clear markers
          </button>
        </div>



        <ReactGlobe
          height="100vh"
          markers={markers}
          options={options}
          width="100%"
          onClickMarker={onClickMarker}
          onDefocus={onDefocus}
        />
      </div>

      <div className="con-from" style={{ backgroundColor: "#0A0A0A" }}>
        <div className="container">
          <form onSubmit={handleSubmit} className="call-request-form">
            <h2 className="form-title">
              Request a <br />
              <span>Call Back</span>
            </h2>
            <div className="row">
              <div className="col">
                <div className="form-row">
                  <input
                    type="text"
                    placeholder="First Name"
                    id="first-name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-row">
                  <input
                    type="text"
                    placeholder="Last Name"
                    id="last-name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-row">
                  <input
                    type="tel"
                    id="phone-no"
                    placeholder="Phone No"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-row">
                  <input
                    type="email"
                    placeholder="Mail Id"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <textarea
                id="message"
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>
            <div className="con-ra-btn">
              <button
                className="con-btn"
                // style={{
                //   borderRadius: "0px",
                //   border: "2px solid #fff",
                // }}
                
                variant=""
                size="lg"
                active
              >
                Send Now
                
              </button>
            </div>
            {statusMessage && <p className="status-message">{statusMessage}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;

const rootElement = document.getElementById("root");
ReactDOM.render(<Contact />, rootElement);