import React, { Fragment, useState } from "react";
import { Link} from "react-router-dom";
import axios from "axios";
import "./Partnersign.css";

const PartnerSign = () => {

  // const [history, useHistory] = useState();


  // const history = useHistory({});

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
  });

  const { name, email, password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      console.log("Passwords do not match");
    } else {
      const newUser = {
        name,
        email,
        password,
      };
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const body = JSON.stringify(newUser);
        const res = await axios.post("https://brocofinancial.com/backend/public/api/signup", body, config);
        console.log(res.data);
        // Redirect to PartnerLogin page after successful registration
        // history.push("/Partnerlogin");
      } catch (err) {
        console.error(err.response.data);
      }
    }
  };

  return (
    <Fragment>
      <div className="card1">
      <h1 className='ra-head'>Sign Up</h1>
      <form className='form' onSubmit={(e) => onSubmit(e)}>

        <div className="ra-signup">
        <div className='form-group'>
          <input
            onChange={onChange}
            type='text'
            placeholder='Name'
            name='name'
            value={name}
            required
          />
        </div>
        
        <div className='form-group'>
          <input
            onChange={onChange}
            type='email'
            placeholder='Email Address'
            name='email'
            value={email}
          />
          {/* <small className='form-text'>
            This site uses Gravatar so if you want a profile image, use a
            Gravatar email
          </small> */}
        </div>
        <div className='form-group'>
          <input
            onChange={onChange}
            type='password'
            placeholder='Password'
            name='password'
            minLength='6'
            value={password}
          />
        </div>
        <div className='form-group'>
          <input
            onChange={onChange}
            type='password'
            placeholder='Confirm Password'
            name='password2'
            minLength='6'
            value={password2}
          />
        </div>
        </div>
        <input
          onChange={onChange}
          type='submit'
          className='btn btn-primary'
          value='Register'
        />
      </form>
      <p className='my-1'>
        Already have an account? <span><Link to='/Partnerlogin' style={{
          textDecoration:"none",
        }}>Sign In</Link></span>
      </p>

      </div>
    </Fragment>
  );
};

export default PartnerSign;